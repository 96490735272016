import { gql } from '@apollo/client';

export const GET_CONFIG_MUTATION = gql`
  mutation GET_CONFIG_MUTATION($type: String) {
    getConfig(type: $type) {
      publicKey
      priceCode
    }
  }
`;
export const CREATE_CHECKOUT_SESSION_MUTATION = gql`
  mutation CREATE_CHECKOUT_SESSION_MUTATION(
    $cancelUrl: String!
    $email: String!
    $id: String!
    $locale: String!
    $priceCode: String
  ) {
    createCheckoutSession(
      cancelUrl: $cancelUrl
      email: $email
      id: $id
      locale: $locale
      priceCode: $priceCode
    ) {
      sessionId
    }
  }
`;

export const GET_FREE_DOCUMENT_MUTATION = gql`
  mutation GET_FREE_DOCUMENT_MUTATION($documentId: String!) {
    getFreeDocument(documentId: $documentId) {
      fileUrl
    }
  }
`;
